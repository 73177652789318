<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/individual.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/individual.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/offer">Wróć do spis usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO PRACY</h4>
		</template>

		<template #title>
			<h2>Indywidualne prawo pracy</h2>
		</template>

		<template #text>
			<p>Obszar indywidualnego prawa pracy obejmuje codzienną, bieżącą obsługę prawną między innymi w
				zakresie:</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">bieżącego wsparcia działu HR w zakresie obowiązków Pracodawcy i uprawnień
					pracowników (urlopy, czas pracy, uprawnienia rodzicielskie, inne);
				</li>
				<li class="offer__list-item">udzielania porad prawnych dotyczących relacji Pracownik – Pracodawca
					(systemy i rozkłady czasu pracy, urlopy, Fundusz Socjalny, zakończenia umów, zmiany warunków
					zatrudnienia), interpretacji oraz konsultacji, a także sporządzania opinii prawnych z zakresu
					indywidualnego prawa pracy;
				</li>
				<li class="offer__list-item">nawiązywania i rozwiązywania stosunków pracy oraz kontraktów menadżerskich
					– przygotowanie indywidualnych umów o pracę, dokumentów rozwiązujących stosunek pracy, umów
					dodatkowych związanych ze stosunkiem pracy, umów o zakazie konkurencji, porozumień z pracownikami,
					pism zmieniających warunki pracy i płacy, organizowanie elastycznych form zatrudnienia oraz
					przeprowadzanie tzw. „trudnych zwolnień” pracowników chronionych i pracowników wysokiego szczebla;
				</li>
				<li class="offer__list-item">prowadzenia postępowań wyjaśniających w obszarze mobbingu, dyskryminacji i
					równego traktowania oraz przygotowania procedury antymobbingowej i antydyskryminacyjnej oraz
					prowadzenie szkoleń w tym zakresie.
				</li>

			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'indyvidual labor law',
}
</script>